import * as firebase from "firebase/auth";
import { auth } from "./firebase";

export interface User {
    email: string;
}

export default class Auth {
    auth: firebase.Auth;

    constructor() {
        this.auth = auth;
    }

    onAuthStateChanged(callback: (user: User | null) => void): void {
        this.auth.onAuthStateChanged((fbUser) => {
            if (fbUser) {
                callback({ email: fbUser.email });
            } else {
                callback(null);
            }
        });
    }

    async signIn(email: string, password: string): Promise<void> {
        await firebase.signInWithEmailAndPassword(this.auth, email, password);
    }

    async signOut(): Promise<void> {
        await firebase.signOut(this.auth);
    }
}
