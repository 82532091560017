import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type ViewType = "Login" | "Loading" | "App";

interface ViewState {
    view: ViewType;
}

const initialState: ViewState = {
    view: "Loading",
};

const viewSlice = createSlice({
    name: "view",
    initialState,
    reducers: {
        /* eslint-disable no-param-reassign */
        setView: (state, action: PayloadAction<ViewType>) => {
            state.view = action.payload;
        },
        /* eslint-enable no-param-reassign */
    },
});

export const { setView } = viewSlice.actions;

export default viewSlice.reducer;
