import {
    createSlice,
    PayloadAction,
    Middleware,
    MiddlewareAPI,
} from "@reduxjs/toolkit";
import { Action } from "redux";
import * as firebase from "firebase/database";
import Types, { AppDispatch } from "Types";

import { db } from "../lib/firebase";

const ref = firebase.ref(db, "printer");
const svgRef = firebase.ref(db, "printer/svg");

interface PrintState {
    svg: string;
    queueLength: number;
}

const initialState: PrintState = {
    svg: "",
    queueLength: 0,
};

const printSlice = createSlice({
    name: "printSlice",
    initialState,
    reducers: {
        /* eslint-disable no-param-reassign */
        setSvg: (state, action: PayloadAction<string>) => {
            state.svg = action.payload;
        },
        setQueueLength: (state, action: PayloadAction<number>) => {
            state.queueLength = action.payload;
        },
        /* eslint-enable no-param-reassign */
    },
});

export default printSlice.reducer;

export const onPrintQueueUpdate: Middleware = ({ dispatch }:
MiddlewareAPI<Types.AppDispatch, Types.RootState>) => {
    firebase.onValue(firebase.query(ref), (snapshot) => {
        if (snapshot.exists()) {
            const val = snapshot.val();

            if (snapshot.hasChild("svg")) {
                dispatch(printSlice.actions.setSvg(val.svg));
            }

            if (snapshot.hasChild("queueLength")) {
                dispatch(printSlice.actions.setQueueLength(val.queueLength));
            }
        }
    });

    return (next: Types.AppDispatch) => (action: Action) => next(action);
};

export const setSvg = (svg: string) => async (dispatch: AppDispatch) => {
    dispatch(printSlice.actions.setSvg(svg));
    await firebase.set(svgRef, svg);
};
