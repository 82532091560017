import React, { useState, useEffect } from "react";
import {
    Grid,
    Paper,
    Typography,
    Button,
} from "@mui/material";
import { useAppSelector, useAppDispatch, RootState } from "../store";
import {
    registerItem,
    getLastRegisteredItems,
    deleteItems,
    clearRegisteredItems,
    PAGE_SIZE,
} from "../ducks/submissions";
import { SelectableTable, AlignType } from "./SelectableTable";
import InputGroup from "./InputGroup";

const headerConfig = {
    order: ["submission", "price"],
    config: {
        submission: {
            text: "Inlämningsnummer",
            align: "right" as AlignType,
        },
        price: {
            text: "Pris (kr)",
            align: "right" as AlignType,
        },
    },
};

export default () => {
    const dispatch = useAppDispatch();
    const { items, status } = useAppSelector(
        (state: RootState) => state.registeredItems,
    );

    const [selectedKeys, setSelectedKeys] = useState<string[]>([]);
    const [chunkIndex, setChunkIndex] = useState<number>(-1);
    const [refs, setRefs] = useState<any[]>(undefined);

    const verifyInput = (input: string[]) => input.every((val) => /^\d*$/.test(val));
    const onRegisterInputChange = (input: string[], givenRefs: any[]) => {
        setRefs(givenRefs);

        // Always focus on "Pris" once "Inlämningsnummer" has length 4.
        if (input[0].length === 4) {
            givenRefs[1].current.focus();
        }
    };
    const onRegisterInput = async (input: string[]) => {
        await dispatch(registerItem({
            submission: parseInt(input[0], 10),
            price: parseInt(input[1], 10),
        }));
        refs[0].current.focus();
    };
    const onFetchClick = async () => {
        setChunkIndex(chunkIndex + 1);
        await dispatch(getLastRegisteredItems(chunkIndex + 1));
    };
    const onCheck = (id: KeyType) => {
        if (selectedKeys.includes(id)) {
            setSelectedKeys(selectedKeys.filter((key) => key !== id));
        } else {
            setSelectedKeys([...selectedKeys, id]);
        }
    };
    const onClear = () => {
        dispatch(clearRegisteredItems());
        setChunkIndex(-1);
    };

    const sum = items.reduce((s, item) => s + item.price, 0);

    useEffect(() => {
        setSelectedKeys([]);
    }, [items]);

    return (
        <Paper sx={{ padding: 2 }}>
            <Grid container spacing={2} direction="row">
                <Grid item xs={12}>
                    <Typography variant="h5">Registrera såld vara</Typography>
                </Grid>
                <Grid item xs={12}>
                    <InputGroup
                        labels={["Inlämningsnummer", "Pris"]}
                        buttonLabel="Registrera"
                        verifyInput={verifyInput}
                        onInputChange={onRegisterInputChange}
                        onButtonClick={onRegisterInput}
                        loading={status === "loading"}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Grid
                        container
                        spacing={2}
                        sx={{
                            justify: "space-between",
                        }}
                    >
                        <Grid item>
                            <Typography variant="h5">Senast registrerade varor</Typography>
                        </Grid>
                        <Grid item>
                            <Button
                                disabled={items.length === 0}
                                onClick={onClear}
                            >
                                Rensa
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <SelectableTable
                        headers={headerConfig}
                        body={items.map((item) => ({ ...item })).reverse()}
                        onCheck={onCheck}
                        selected={selectedKeys}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h6">
                        {`Summa ${sum} kr`}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Button onClick={onFetchClick} disabled={status === "loading"}>
                        {`Hämta ${PAGE_SIZE} fler varor`}
                    </Button>
                    {selectedKeys.length > 0
                        && <Button color="error" onClick={() => dispatch(deleteItems(items.filter((item) => selectedKeys.includes(item.id))))}>
                            Ta bort {selectedKeys.length} {selectedKeys.length > 1 ? "varor" : "vara"}
                        </Button>}
                </Grid>
            </Grid>
        </Paper>
    );
};
