import React from "react";
import {
    CircularProgress,
    CssBaseline,
    Grid,
} from "@mui/material";
// import * as firebase from "firebase/app";
// import * as database from "firebase/database";
// import { getAuth, connectAuthEmulator, signOut, onAuthStateChanged,
// signInWithEmailAndPassword } from "firebase/auth";
// import { DatabaseCollection, DatabaseItem, Database, PrintQueueCollection } from "../database";
// import { OrderedMap } from "immutable";
// import { AuthStub, FirebaseAuth } from "../auth";
// import { App } from "./App";
// import { Login } from "./Login";
import { useAppSelector } from "../store";
import Login from "./Login";
import App from "./App";

const Loading = () => (
    <Grid container sx={{
        justify: "center",
        alignContent: "center",
    }}>
        <Grid item>
            <CircularProgress />
        </Grid>
    </Grid>
);

/*
export class _Router extends Component<RouterProps, RouterState> {
  private readonly db: Database;
  private readonly printQueue: Database;

  constructor(props: RouterProps) {
    super(props);

    this.db = new Database(db, "products");
    this.printQueue = new Database(db, "printQueue");

    onAuthStateChanged(auth, (user) => {
      console.log("onAuthStateChanged", user);
      if (user) {
        this.setView("Loading");
        this.getLastItems(0).then(() => this.setView("App"));
      }
      else {
        this.setView("Login");
      }
    });

    this.state = {
      lastItems: OrderedMap(),
      filteredItems: OrderedMap(),
      printQueue: OrderedMap(),
      view: "Loading",
    };
  }

  private setView(view: ViewType) {
    this.setState({ view });
  }

  private async login(username: string, password: string) {
    this.setView("Loading");

    try {
      console.log("signing in", username, password);
      await signInWithEmailAndPassword(auth, username, password);
    } catch (e) {
      console.error(e);
      this.setView("Login");
    }
  }

  private async logout() {
    this.setView("Loading");
    await auth.signOut();
  }

  private async createItem(item: DatabaseItem) {
    const key = await this.db.push(item);

    this.setState((prevState) => ({
      lastItems: prevState.lastItems.set(key, item),
    }));
  }

  private async removeItems(keys: string[]) {
    await this.db.remove(keys);

    this.setState((prevState) => ({
      lastItems: prevState.lastItems.deleteAll(keys),
    }));
  }

  private async getLastItems(chunkIndex: number) {
    const coll = await this.db.get(database.limitToLast(2 + 2 * chunkIndex));

    this.setState({ lastItems: coll });
  }

  private clearFilteredItems() {
    this.setState({ filteredItems: this.state.filteredItems.clear() })
  }

  private async getItemsBySubmission(subm: number) {
    this.clearFilteredItems();

    const coll = await this.db.get(database.orderByChild("submission"),
      database.equalTo(subm));

    this.setState({ filteredItems: coll });
  }

  private async pushPrintQueue(svg: string) {
    await this.printQueue.push(svg);
  }

  public render() {
    const view = useAppSelector((state) => state.view.value);
    const dispatch = useAppDispatch();

    return (
      <React.Fragment>
        <CssBaseline />
        {["Init", "Idle", "Authenticate", "Loading"].includes
          (this.state.view) && <Loading />}
        {this.state.view === "Login" && <Login
          onLogin={(un, pw) => this.login(un, pw)} />}
        {this.state.view === "App" && <App
          registeredItems={this.state.lastItems}
          filteredItems={this.state.filteredItems}
          createItem={(item) => this.createItem(item)}
          deleteItems={(keys: string[]) => this.removeItems(keys)}
          getItems={(idx) => this.getLastItems(idx)}
          filterItems={(subm) => this.getItemsBySubmission(subm)}
          clearFilter={() => this.clearFilteredItems()}
          signOut={() => this.logout()}
          pushPrintQueue={(svg) => this.pushPrintQueue(svg)}
        />}
      </React.Fragment>
    );
  };
}
*/

export default () => {
    const view = useAppSelector((state) => state.view.view);

    return (
        <React.Fragment>
            <CssBaseline />
            {view === "Loading" && <Loading />}
            {view === "Login" && <Login />}
            {view === "App" && <App />}
        </React.Fragment>
    );
};
