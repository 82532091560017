import {
    createSlice,
    PayloadAction,
    SliceCaseReducers,
    ValidateSliceCaseReducers,
} from "@reduxjs/toolkit";
import Types from "Types";
import Submissions, { SubmissionItem } from "../lib/submissions";

const subs = new Submissions();
export const PAGE_SIZE = 5;

interface SubmissionsState {
    items: Array<SubmissionItem>;
    status: "loading" | "finished" | "error";
}

const createSubmissionsSlice = <Reducers extends SliceCaseReducers<SubmissionsState>>({
    name = "",
    initialState = {
        items: [],
        status: "finished",
    },
    reducers,
}: {
    name: string;
    initialState: SubmissionsState;
    reducers: ValidateSliceCaseReducers<SubmissionsState, Reducers>;
}) => createSlice({
    name,
    initialState,
    reducers: {
        /* eslint-disable no-param-reassign */
        setItems: (
            state: SubmissionsState,
            action: PayloadAction<Array<SubmissionItem>>,
        ) => {
            state.items = action.payload;
        },
        pushItems: (
            state: SubmissionsState,
            action: PayloadAction<SubmissionItem>,
        ) => {
            state.items.push(action.payload);
        },
        removeItems: (
            state: SubmissionsState,
            action: PayloadAction<Array<string>>,
        ) => {
            state.items = state.items.filter(
                (item) => action.payload.includes(item.id),
            );
        },
        clearItems: (state: SubmissionsState) => {
            state.items = [];
        },
        setStatus: (
            state: SubmissionsState,
            action: PayloadAction<SubmissionsState["status"]>,
        ) => {
            state.status = action.payload;
        },
        ...reducers,
        /* eslint-enable no-param-reassign */
    },
});

export const registeredItemsSlice = createSubmissionsSlice({
    name: "submissions/registeredItems",
    initialState: {
        items: [],
        status: "finished",
    },
    reducers: {},
});

export const filteredItemsSlice = createSubmissionsSlice({
    name: "submissions/filteredItems",
    initialState: {
        items: [],
        status: "finished",
    },
    reducers: {},
});

/* Items about to be cleared. */
export const clearableItemsSlice = createSubmissionsSlice({
    name: "submissions/clearableItems",
    initialState: {
        items: [],
        status: "finished",
    },
    reducers: {},
});

export const getLastRegisteredItems = (page: number) => async (dispatch: Types.AppDispatch) => {
    dispatch(registeredItemsSlice.actions.setStatus("loading"));
    dispatch(registeredItemsSlice.actions.clearItems());
    dispatch(registeredItemsSlice.actions.setItems(await subs.fetchLast((page + 1) * PAGE_SIZE)));
    dispatch(registeredItemsSlice.actions.setStatus("finished"));
};

export const getItemsBySubmission = (subm: number) => async (dispatch: Types.AppDispatch) => {
    dispatch(filteredItemsSlice.actions.setStatus("loading"));
    dispatch(filteredItemsSlice.actions.clearItems());
    dispatch(filteredItemsSlice.actions.setItems(await subs.fetchSubmission(subm)));
    dispatch(filteredItemsSlice.actions.setStatus("finished"));
};

export const registerItem = (item: SubmissionItem) => async (dispatch: Types.AppDispatch) => {
    dispatch(registeredItemsSlice.actions.setStatus("loading"));
    dispatch(filteredItemsSlice.actions.clearItems());
    dispatch(registeredItemsSlice.actions.pushItems(await subs.push(item)));
    dispatch(registeredItemsSlice.actions.setStatus("finished"));
};

export const clearRegisteredItems = () => (dispatch: Types.AppDispatch) => {
    dispatch(registeredItemsSlice.actions.clearItems());
};

export const deleteItems = (items: SubmissionItem[]) => async (dispatch: Types.AppDispatch) => {
    dispatch(registeredItemsSlice.actions.setStatus("loading"));
    dispatch(filteredItemsSlice.actions.clearItems());
    dispatch(clearableItemsSlice.actions.clearItems());

    await subs.delete(items);
    dispatch(registeredItemsSlice.actions.setStatus("finished"));

    return dispatch(getLastRegisteredItems(0));
};

export const getItemsBySubmissionRange = (from: number, to: number) => async (
    dispatch: Types.AppDispatch,
) => {
    dispatch(clearableItemsSlice.actions.setStatus("loading"));
    dispatch(clearableItemsSlice.actions.clearItems());

    dispatch(clearableItemsSlice.actions.setItems(await subs.fetchSubmissionRange(from, to)));
    dispatch(clearableItemsSlice.actions.setStatus("finished"));
};
