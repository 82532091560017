import React, { FunctionComponent, useState } from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Grid,
    Paper,
    Typography,
    Button,
    CircularProgress,
} from "@mui/material";
import { AlignType, SelectableTable } from "./SelectableTable";

import { useAppSelector, useAppDispatch, RootState } from "../store";
import {
    getItemsBySubmission,
    filteredItemsSlice,
    deleteItems,
} from "../ducks/submissions";
import type { SubmissionItem } from "../lib/submissions";
import InputGroup from "./InputGroup";

interface ProvisionInformationProps {
    items: Array<SubmissionItem>;
    onRemove: (items: SubmissionItem[]) => Promise<void>;
    clearFilter: () => void;
}

const sumTableHeaderConfig = {
    order: ["description", "value"],
    config: {
        description: {
            text: "Beskrivning",
            align: "left" as AlignType,
        },
        value: {
            text: "Värde",
            align: "left" as AlignType,
        },
    },
};
const productTableHeaderConfig = {
    order: ["price", "sharePercent", "shareResult"],
    config: {
        price: {
            text: "Pris (kr)",
            align: "right" as AlignType,
        },
        sharePercent: {
            text: "Andel provision (%)",
            align: "right" as AlignType,
        },
        shareResult: {
            text: "Andel provision (kr)",
            align: "right" as AlignType,
        },
    },
};

const ProvisionInformation: FunctionComponent<ProvisionInformationProps> = (props) => {
    const [dialogOpen, setDialogOpen] = useState<boolean>(false);
    const [removing, setRemoving] = useState<boolean>(false);
    const onDialogClose = () => !removing && setDialogOpen(false);
    const onDialogOpen = () => setDialogOpen(true);
    const onItemsRemove = () => {
        setRemoving(true);
        props.onRemove(props.items).then(() => {
            setRemoving(false);
            setDialogOpen(false);
            props.clearFilter();
        });
    };
    const itemsBody = props.items.reduce((body, item, key) => {
        const sharePercent = item.price >= 300 ? 0.5 : 0.4;
        const shareResult = item.price * sharePercent;

        return [
            ...body,
            {
                id: key,
                price: item.price,
                sharePercent: sharePercent * 100,
                shareResult,
            },
        ];
    }, []);
    const sumTotal = props.items.reduce((s, item) => s + item.price, 0);
    const sumProvision = itemsBody.reduce((s, item) => s + item.shareResult, 0);
    const sumTable = [
        {
            id: "0",
            description: "Inlämningsnummer",
            value: props.items[0].submission,
        },
        {
            id: "1",
            description: "Antal varor",
            value: props.items.length,
        },
        {
            id: "2",
            description: "Summa total",
            value: `${sumTotal} kr`,
        },
        {
            id: "3",
            description: "Summa provision",
            value: `${sumProvision} kr`,
        },
    ];

    return (
        <React.Fragment>
            <Dialog open={dialogOpen} onClose={onDialogClose}>
                <DialogTitle>
                    Ta bort varor permanent
                </DialogTitle>
                <DialogContent>
                    {props.items.length} {props.items.length > 1 ? "varor" : "vara"} med inlämningsnummer {props.items[0].submission} kommer tas bort permament från databasen.
                </DialogContent>
                <DialogActions>
                    <Button onClick={onDialogClose} disabled={removing}>Avbryt</Button>
                    <Button color="error" onClick={onItemsRemove} disabled={removing}>
                        Ta bort {removing
                            && <CircularProgress sx={{ marginLeft: 1 }} size={20} />}
                    </Button>
                </DialogActions>
            </Dialog>
            <Grid item xs={12}>
                <Grid
                    container
                    spacing={2}
                    sx={{
                        justify: "space-between",
                    }}
                >
                    <Grid item>
                        <Typography variant="h5">Beräknad provision</Typography>
                    </Grid>
                    <Grid item>
                        <Button onClick={props.clearFilter}>Rensa</Button>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>
                <SelectableTable
                    headers={sumTableHeaderConfig}
                    showHeaders={false}
                    body={sumTable}
                />
            </Grid>
            <Grid item xs={12}>
                <Typography variant="h5">Sålda varor</Typography>
            </Grid>
            <Grid item>
                <SelectableTable
                    headers={productTableHeaderConfig}
                    body={itemsBody}
                />
            </Grid>
            <Grid item>
                <Button color="error" onClick={onDialogOpen}>
                    Ta bort {props.items.length} {props.items.length > 1 ? "varor" : "vara"}
                </Button>
            </Grid>
        </React.Fragment>
    );
};

const NoProvisionInformation = () => (
    <Grid item>
        <Typography variant="body2">Inga varor hittade</Typography>
    </Grid>
);

export default () => {
    const dispatch = useAppDispatch();
    const { items, status } = useAppSelector(
        (state: RootState) => state.filteredItems,
    );
    const [searchInitiated, setSearchInitiated] = useState<boolean>(false);
    const verifyInput = (input: string[]) => input.every((val) => /^\d*$/.test(val));
    const onCalculateProvision = async (input: string[]) => {
        setSearchInitiated(true);
        await dispatch(getItemsBySubmission(parseInt(input[0], 10)));
    };
    const onClearClick = () => {
        setSearchInitiated(false);
        dispatch(filteredItemsSlice.actions.clearItems());
    };

    return (
        <Paper sx={{ padding: 2 }}>
            <Grid container spacing={2} direction="column">
                <Grid item>
                    <Typography variant="h5">Beräkna provision</Typography>
                </Grid>
                <Grid item>
                    <InputGroup
                        labels={["Inlämningsnummer"]}
                        buttonLabel="Beräkna"
                        verifyInput={verifyInput}
                        onButtonClick={onCalculateProvision}
                        loading={status === "loading"}
                    />
                </Grid>
                {items.length > 0
                && status !== "loading"
                && <ProvisionInformation
                    onRemove={async (i) => dispatch(deleteItems(i))}
                    items={items}
                    clearFilter={onClearClick}
                />}
                {searchInitiated
                && !items.length
                && status !== "loading"
                && <NoProvisionInformation />}
            </Grid>
        </Paper>
    );
};
