import React from "react";
import {
    AppBar,
    Button,
    Container,
    Grid,
    Toolbar,
    Typography,
} from "@mui/material";
import Provision from "./Provision";
import Register from "./Register";
import Print from "./Print";
import Clear from "./Clear";
import { useAppDispatch } from "../store";
import { signOut } from "../ducks/auth";

export default () => {
    const dispatch = useAppDispatch();

    return (
        <Container maxWidth="md">
            <AppBar>
                <Container maxWidth="md">
                    <Toolbar>
                        <Grid container sx={{
                            direction: "row",
                            justifyContent: "space-between",
                        }}>
                            <Grid item>
                                <Typography variant="h6">Lilla Livs Garderob - Provision</Typography>
                            </Grid>
                            <Grid item>
                                <Button
                                    color="inherit"
                                    onClick={() => dispatch(signOut())}>
                                    Logga ut
                                </Button>
                            </Grid>
                        </Grid>
                    </Toolbar>
                </Container>
            </AppBar>
            <Grid
                container
                sx={{
                    marginTop: "5rem",
                    marginBottom: "2rem",
                }}
                spacing={2}
            >
                <Grid item md={6}>
                    <Register />
                </Grid>
                <Grid item md={6}>
                    <Provision />
                </Grid>
                <Grid item md={6}>
                    <Print />
                </Grid>
                <Grid item md={6}>
                    <Clear />
                </Grid>
            </Grid>
        </Container>
    );
};
