import { initializeApp } from "firebase/app";
import { getDatabase, connectDatabaseEmulator } from "firebase/database";
import { getAuth, connectAuthEmulator } from "firebase/auth";

const firebaseConfig = {
    apiKey: "AIzaSyCwIU6hcmdaEwygrMnVPnzX6qxiuw-eJS0",
    authDomain: "lilla-livs-garderob-6d3f8.firebaseapp.com",
    databaseURL: "https://lilla-livs-garderob-6d3f8.firebaseio.com",
    projectId: "lilla-livs-garderob-6d3f8",
    storageBucket: "",
    messagingSenderId: "976277384127",
    appId: "1:976277384127:web:52c23f5683b66288d10f0a",
};

const app = initializeApp(firebaseConfig);

export const db = getDatabase(app);
export const auth = getAuth(app);

if (["development", "test"].includes(process.env.NODE_ENV)) {
    connectAuthEmulator(auth, "http://127.0.0.1:9099");
    connectDatabaseEmulator(db, "localhost", 9000);
}
