import React, { FunctionComponent } from "react";
import {
    Checkbox,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
} from "@mui/material";

export type AlignType = "inherit" | "left" | "center" | "right" | "justify";
export interface HeaderConfigType {
    order: string[];
    config: {
        [key: string]: {
            text: string;
            align: AlignType;
        };
    };
}
interface BodyType {
    id?: string;
    [key: string]: any;
}

export interface SelectableTableProps {
    headers: HeaderConfigType;
    showHeaders?: boolean;
    body: BodyType[];
    selected?: string[];
    onCheck?: (id: string) => void;
}

interface SelectableTableRowProps {
    key: string;
    selected: boolean;
    cell: BodyType;
    headers: HeaderConfigType;
    onCheck: (id: string) => void;
}

const SelectableTableRow: FunctionComponent<SelectableTableRowProps> = (props) => (
    <TableRow>
        {props.onCheck && <TableCell padding="checkbox">
            <Checkbox
                onClick={() => props.onCheck(props.cell.id)}
                checked={props.selected}
            />
        </TableCell>}
        {props.headers.order.map((header) => (
            <TableCell
                key={`${header}-${props.cell.id}`}
                align={props.headers.config[header].align}
            >
                {props.cell[header]}
            </TableCell>
        ))}
    </TableRow>
);

export const SelectableTable: FunctionComponent<SelectableTableProps> = (props) => (
    <Table size="small">
        {(props.showHeaders === undefined ? true : props.showHeaders)
            && <TableHead>
                <TableRow>
                    {props.onCheck && props.selected
                        && <TableCell padding="checkbox" />}
                    {props.headers.order.map((header) => (
                        <TableCell
                            align={props.headers.config[header].align}
                            key={header}
                        >
                            {props.headers.config[header].text}
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>
        }
        <TableBody>
            {props.body.map((body) => (
                <SelectableTableRow
                    cell={body}
                    selected={props.selected ? props.selected.includes(body.id) : false}
                    key={body.id}
                    headers={props.headers}
                    onCheck={props.onCheck}
                />
            ))}
        </TableBody>
    </Table>
);
