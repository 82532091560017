import * as firebase from "firebase/database";
import { db } from "./firebase";

export interface SubmissionItem {
    id?: string;
    submission: number;
    price: number;
}

export default class Submissions {
    db: firebase.Database;

    ref: firebase.DatabaseReference;

    constructor() {
        this.db = db;
        this.ref = firebase.ref(this.db, "products");
    }

    async push(item: SubmissionItem): Promise<SubmissionItem> {
        const { key: id } = await firebase.push(this.ref, item);

        return { id, ...item };
    }

    private async fetch(...constraints: firebase.QueryConstraint[]): Promise<SubmissionItem[]> {
        const snapshot = await firebase.get(firebase.query(this.ref, ...constraints));

        if (snapshot.exists()) {
            const val = snapshot.val();

            return Object.keys(val).map((key) => ({ ...val[key], id: key }));
        }

        return [];
    }

    async fetchLast(n: number): Promise<SubmissionItem[]> {
        return this.fetch(firebase.limitToLast(n));
    }

    async fetchSubmission(submission: number): Promise<SubmissionItem[]> {
        return this.fetch(firebase.orderByChild("submission"), firebase.equalTo(submission));
    }

    async fetchSubmissionRange(from: number, to:number): Promise<SubmissionItem[]> {
        return this.fetch(firebase.orderByChild("submission"), firebase.startAt(from), firebase.endAt(to));
    }

    async delete(items: SubmissionItem[]): Promise<void> {
        const ios = [];
        const itemsCopy = [...items];

        /* Chunk it up. */
        while (itemsCopy.length > 0) {
            const chunk = itemsCopy.splice(0, Math.min(100, items.length));

            ios.push(firebase.update(
                this.ref,
                chunk.reduce(
                    (acc, item) => ({ ...acc, [item.id]: null }),
                    {},
                ),
            ));
        }

        await Promise.all(ios);
    }

    async deleteBySubmission(submission: number): Promise<void> {
        await this.delete(await this.fetchSubmission(submission));
    }
}
