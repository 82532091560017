import { configureStore, createAsyncThunk } from "@reduxjs/toolkit";
import { useDispatch, useSelector, TypedUseSelectorHook } from "react-redux";
import viewReducer from "./ducks/view";
import { registeredItemsSlice, filteredItemsSlice, clearableItemsSlice } from "./ducks/submissions";
import { onAuthStateChangedMiddleware } from "./ducks/auth";
import printReducer, { onPrintQueueUpdate } from "./ducks/print";

const store = configureStore({
    reducer: {
        view: viewReducer,
        registeredItems: registeredItemsSlice.reducer,
        filteredItems: filteredItemsSlice.reducer,
        clearableItems: clearableItemsSlice.reducer,
        print: printReducer,
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(
        onAuthStateChangedMiddleware,
        onPrintQueueUpdate,
    ),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const createAppAsyncThunk = createAsyncThunk.withTypes<{
    state: RootState,
    dispatch: AppDispatch,
}>();

export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export default store;
