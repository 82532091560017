import React, { useState } from "react";
import {
    Container,
    Grid,
    Paper,
    Typography,
} from "@mui/material";
import InputGroup from "./InputGroup";
import { useAppDispatch } from "../store";
import { signIn } from "../ducks/auth";

export default () => {
    const dispatch = useAppDispatch();
    const [isRetry, setIsRetry] = useState<boolean>(false);
    const verifyInput = ([username]: string[]) => /^[\w.]+@[\w.]+$/.test(username);
    const onButtonClick = ([username, password]: string[]) => {
        setIsRetry(true);
        dispatch(signIn(username, password));
    };
    const onInputChange = (input: string[], refs: any[]) => {
        if (input.every((field: string) => field.length === 0)) {
            refs[0].current.focus();
        }
    };

    return (
        <Container maxWidth="md">
            <Grid
                container
                sx={{
                    marginTop: "8rem",
                    justifyContent: "center",
                    alignContent: "center",
                }}
                spacing={2}
            >
                <Grid item>
                    <Paper sx={{
                        padding: 2,
                        maxWidth: "20rem",
                        textAlign: "center",
                    }}>
                        <Grid container sx={{ justifyContent: "center" }} spacing={2}>
                            <Grid item>
                                <Typography variant="h5">Lilla Livs Garderob</Typography>
                                <Typography variant="caption">Beräkna provision</Typography>
                            </Grid>
                            {isRetry && <Grid item>
                                <Typography variant="body2" color="error">Felaktiga användaruppgifter</Typography>
                            </Grid>}
                            <Grid item>
                                <InputGroup
                                    labels={["Användarnamn", "Lösenord"]}
                                    buttonLabel="Logga in"
                                    warningText="Ogiltigt användarnamn"
                                    onButtonClick={onButtonClick}
                                    onInputChange={onInputChange}
                                    verifyInput={verifyInput}
                                    loading={false}
                                />
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
        </Container>
    );
};
